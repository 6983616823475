/** @jsx jsx */
import { Themed, jsx } from "theme-ui"

import Layout from "../components/layout"
import ResponsiveEmbed from "../components/responsive-embed"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Section = ({ title, description, externalUrl, children }) => (
  <section sx={{ mb: [3, 4] }}>
    <Themed.h2 sx={{ fontSize: 3 }}>
      <Themed.a href={externalUrl} target="_blank">
        {title}
      </Themed.a>
    </Themed.h2>
    <Themed.p sx={{ maxWidth: "30em", color: "#888" }}>{description}</Themed.p>
    {children}
  </section>
)

const MusicPage = ({ data: { allYoutubeVideo } }) => (
  <Layout>
    <SEO title="Music" />
    <Themed.h1 sx={{ mb: 4 }}>Music</Themed.h1>
    <Section
      title="D'Souza & Le Project"
      description=" A progressive rock/metal band I started with a good friend. Here's a
      concept album we put together."
      externalUrl="https://dsouzaleproject.bandcamp.com/"
    >
      <iframe
        sx={{
          width: "100%",
          height: ["472px", "700px"],
          border: 0,
        }}
        seamless="seamless"
        src="https://bandcamp.com/EmbeddedPlayer/album=3217870219/size=large/bgcol=ffffff/linkcol=817df2/tracklist=false/artwork=large/transparent=true/"
        title="Eclipse (2017) Bandcamp Embedded Player"
      >
        <a href="http://dsouzaleproject.bandcamp.com/album/eclipse-2017">
          Eclipse (2017) by D'Souza & Le Project
        </a>
      </iframe>
    </Section>

    <Section
      title="YouTube"
      description="I have a small YouTube channel where I share my original music, covers, and remixes. Subscribe if you think it's cool!"
      externalUrl="https://www.youtube.com/channel/UCcRjxpCiZ2YkNZqAlzL_AQA"
    >
      <ResponsiveEmbed
        src={`https://www.youtube-nocookie.com/embed/${allYoutubeVideo.edges[0].node.videoId}`}
        title="YouTube Embedded Player"
      />

      {/* TODO: imagine a little YouTube "player" widget that will change the embed url! */}
      {/* <div
          sx={{
            display: `grid`,
            gridTemplateColumns: ["auto"],
            gridGap: 2,
          }}
        >
          {allYoutubeVideo.edges.slice(1).map(v => {
            return (
              <Video
                thumbnail={v.node.thumbnail}
                url={v.node.url}
                title={v.node.title}
                date={v.node.publishedAt}
              />
            )
          })}
        </div> */}
    </Section>
  </Layout>
)

export default MusicPage

export const pageQuery = graphql`
  query AllYoutubeVideoQuery {
    allYoutubeVideo(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          videoId
          title
          url
          publishedAt
          thumbnail {
            url
            width
            height
          }
        }
      }
    }
  }
`
