/** @jsx jsx */
import { jsx } from "theme-ui"

const ResponsiveEmbed = ({ src, title }) => (
  <div
    sx={{
      position: "relative",
      pb: "56.25%",
      height: 0,
      overflow: "hidden",
      maxWidth: "100%",
      mb: 4,
    }}
  >
    <iframe
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      src={src}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      title={title}
    ></iframe>
  </div>
)

export default ResponsiveEmbed
